<template>
  <app-grid-item
    style="max-height: 500px !important;"
    class="shift__grid__item"
    :item="shift"
  >
    <div
      style="cursor: pointer"
      @click="goToView(shiftProp)"
    >
      <div
        class="grid__header"
        style=" position: relative"
      >
        <div
          v-if="parseInt(shiftProp.unreadMessageNumber)"
          class="unread-message-badge"
        >
          {{ shiftProp.unreadMessageNumber }}
        </div>
        <icon-reload />
        <h2 class="grid__header__title">
          {{ shiftProp.vacancy.title }}
        </h2>
        <AppTextMoney
          class="grid__line__value shift__grid__item__price"
          :money="shiftProp.amount"
        />
      </div>

      <div
        v-if="currentTimer"
        class="grid__line shift__grid__item__line shift__grid__status"
      >
        <span class="shift__grid__status__text">{{ getStatusLabel }}: {{ currentTimer }}</span>
      </div>

      <div class="grid__line shift__grid__item__line">
        <AppTextDateTime class="grid__item__date shift__grid__date">
          <template #date>
            <p>Начало смены:</p>
            {{ shift.startDate }}
          </template>
          <template #time>
            {{ shift.startTime }}
          </template>
        </AppTextDateTime>

        <AppTextDateTime class="grid__item__date shift__grid__date">
          <template #date>
            <p>Конец смены:</p>
            {{ shift.endDate }}
          </template>
          <template #time>
            {{ shift.endTime }}
          </template>
        </AppTextDateTime>
        <div class="grid__line shift__grid__item__line">
          <AppBange style="width: 100%; margin-left: 8px; padding: 6px">
            {{ shift.duration }}
          </AppBange>
        </div>
      </div>
      <div class="grid__line shift__grid__item__line shift__grid__item__owner">
        <span class="shift__grid__item__owner__label">Создатель оффера:</span>
        <span class="shift__grid__item__owner__value">{{ shiftProp.createdBy.fullName }}</span>
      </div>
      <div class="grid__line shift__grid__item__line shift__grid__item__laborery">
        <p style="align-self: start; margin-bottom: 10px">
          Исполнитель:
        </p>
        <OfferItemChatUserCard
          v-if="shiftProp.laborer"
          class="shift-grid__user-info"
          :laborer="shiftProp.laborer"
        />
      </div>
    </div>
    <div
      style="justify-content: space-between ; position: relative"
      class="grid__line shift__grid__item__line"
    >
      <div>
        <AppButton
          v-if=" ['accepted', 'created', 'active'].includes(shiftProp.status) && !shiftProp.startConfirmed"
          style="display: none"
          class="button-text-simple"
          @click.native.prevent="fetchShiftCancel"
        >
          {{ 'Отменить' }}
        </AppButton>

        <div style="font-size: small; color: rgba(0, 0, 0, 0.4)">
          {{ shiftProp.supportNumber }}
        </div>
        <!--        <span-->
        <!--          v-if="shiftProp.status === 'created'"-->
        <!--          class="shift__grid_success"-->
        <!--          @click.prevent.stop="launchShift"-->
        <!--        >Запускать-->
        <!--        </span>-->
      </div>
      <div>
        <span
          v-if="isRejectedInvitation"
          class="button-text-simple"
          style="color: red"
        >
          {{ 'Соискатель отказался' }}
        </span>
        <span
          v-else
          style="color: #8BD118"
          class="button-text-simple"
        >
          {{ getStatus(shiftProp) }}
        </span>
      </div>
      <!--      <div style="position: absolute; bottom: -2px; left: 0; font-size: small; color: rgba(0, 0, 0, 0.4)">-->
      <!--        {{ shiftProp.supportNumber }}-->
      <!--      </div>-->
    </div>
  </app-grid-item>
</template>

<script>
import AppGridItem from '@/components/common/AppGridItem'
import IconReload from '@/components/common/icons/IconReload'
import OfferItemChatUserCard from '@/components/offer/OfferItemChatUserCard'
import AppButton from '@/components/common/simple/AppButton'
import AppTextDateTime from '@/components/common/AppTextDateTime'
import AppBange from '@/components/common/AppBange'
import AppTextMoney from '@/components/common/AppTextMoney'
import routeList from '@/router/labels'
import ShiftMixin from '@/mixins/shift/index.vue'

export default {
  name: 'ShiftGridItem',
  components: { AppTextMoney, AppBange, AppTextDateTime, AppButton, OfferItemChatUserCard, IconReload, AppGridItem },
  mixins: [ShiftMixin],
  props: {
    shiftProp: {
      type: Object,
      required: true
    },
    currentDate: {
      type: Date,
      required: true
    },
  },
  data () {
    return {
      currentTimer: '',
      currentTimerInterval: null
    }
  },
  computed: {
    // currentTimer () {
    //   return this.formatShiftRemainingTime(this.shiftProp, this.hasAbsence)
    // },
    isRejectedInvitation () {
      return this.shift.level && this.shift.level === 'invitation' && this.shift.status === 'rejected'
    },
    hasAbsence () {
      return this.shiftProp && !!this.shiftProp.laborerAbsence && !!this.shiftProp.laborerAbsence.id
    },
    hasRejectRequest () {
      return this.shiftProp && !!this.shiftProp.rejectRequest && !!this.shiftProp.rejectRequest.id
    },
    isConfirmed () {
      return this.shift.startConfirmed
    },
    getStatusLabel () {
      return this.getTimerStatusLabel(this.shiftProp)
    },
    shift () {
      return this.shiftProp.status === 'accepted' ? this.shiftProp.offer : this.shiftProp
    },
    currentOffice () {
      return this.$store.state.office.userOffice
    },
  },
  created () {
    this.currentTimer = this.formatTimerDate(this.shiftProp, this.hasAbsence, this.hasRejectRequest)
    this.currentTimerInterval = setInterval(() => {
      this.currentTimer = this.formatTimerDate(this.shiftProp, this.hasAbsence, this.hasRejectRequest)
    }, 60000)
  },
  beforeDestroy () {
    clearInterval(this.currentTimerInterval)
  },
  methods: {
    goToView (shift) {
      this.$router.push({
        name: routeList.OFFICE_SHIFT_VIEW__NAME,
        params: { id: this.currentOffice.id, shiftId: shift.id },
        query: { type: shift.level || 'relay', status: shift.status },
      })
    },
    launchShift () {
      this.$store.dispatch('shift/launchShift', this.shiftProp)
    },
    fetchShiftCancel () {
      const response = this.shiftProp.status === 'accepted'
        ? this.$store.dispatch('offer/fetchOfferInvitationCancel', { offerInvitationId: this.shiftProp.id })
        : this.$store.dispatch('shift/fetchShiftCancel', this.shiftProp)
          .then(() => {
            this.$emit('finishShift', this.shiftProp)
          })

      response.then(() => {
        this.$store.dispatch('office/fetchOfficeById', { officeId: this.$route.params.id })
        this.$emit('updateShift')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.grid__line
  display: flex
  align-items: center
  border-bottom: $default_border_bottom
  &:last-of-type
    border: 0
  &__label
    @include fontStyle(500, 14px)
    color: rgba(0, 0, 0, 0.6)
  &__value
    @include fontStyle(800, 14px)
    color: black

.wb-bange__info
  @include fontStyle(500, 14px)
  color: rgba(0, 0, 0, 0.21)
  padding: 0 10px 0 20px

.shift__grid__item__line
  padding: 10px 0

.shift__grid__status
  @include fontStyle(500, 14px)
  color: #ED462F
  &__time
    margin-left: 5px
    font-weight: bold

.shift__grid__item__owner
  @include fontStyle(500, 14px)
  color: rgba(0, 0, 0, 0.6)
  white-space: nowrap
  &__value
    margin-left: 10px
    font-weight: 800
    color: black
    text-overflow: ellipsis
    overflow: hidden

.shift__grid__item__laborery
  flex-direction: column


.shift__grid__item__tax
  color: rgba(0, 0, 0, 0.6)
  margin-left: 8px

.shift__grid_success
  @extend %button-text
  color: #8BD118
  padding: 5px 10px
  margin-left: auto
</style>
