<template>
  <div>
    <v-data-table
      :items="mappedShiftList"
      :headers="headers"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="serverItemsLength"
      :footer-props="footerProps"
      class="elevation-0"
      fixed-header
      loading-text="Идет загрузка"
      no-results-text="Список пуст"
      no-data-text="Список пуст"
      dense
    >
      <template v-slot:item.supportNumber="{ item }">
        <v-btn
          link
          text
          color="primary"
          @click.stop="goToView(item)"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-ok
          </v-icon>
          <span style="text-decoration: underline">{{ item.supportNumber }}</span>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AppDateUtil from '@/utils/dateUtl'
import ListMixin from '@/mixins/list-mixin.vue'
import routeList from '@/router/labels'

export default {
  name: 'ShiftTable',
  mixins: [ListMixin],
  props: {
    shiftList: {
      type: Array,
      required: true
    },
    serverItemsLength: {
      type: Number,
      required: 0
    }
  },
  data () {
    return {
      isLoading: false,
      options: {
      },
      headers: [
        { align: 'left', sortable: false, text: '', value: 'icon' },
        { align: 'left', sortable: false, text: 'Номер', value: 'supportNumber' },
        { align: 'left', sortable: false, text: 'Вакансия', value: 'vacancy.title' },
        { align: 'left', sortable: false, text: 'До начала смены', value: '' },
        { align: 'left', sortable: false, text: 'Начало смены', value: 'startDate' },
        { align: 'left', sortable: false, text: 'Конец смены', value: 'endDate' },
        { align: 'left', sortable: false, text: 'часы', value: 'duration' },
        { align: 'left', sortable: false, text: 'ФОТ', value: 'amount' },
        { align: 'left', sortable: false, text: 'Налоги', value: 'Налоги' },
        { align: 'left', sortable: false, text: 'Исполнитель', value: 'laborer.fullName' },
      ],
      keys: [
        { label: '', value: 'icon', config: { canSort: false } },
        { label: 'Номер', value: 'supportNumber', config: { canSort: true } },
        { label: 'Вакансия', value: 'vacancy.title', config: { canSort: true } },
        { label: 'До начала смены', value: '', config: { canSort: true } },
        { label: 'Начало смены', value: 'startDate', config: { canSort: true } },
        { label: 'Конец смены', value: 'endDate', config: { canSort: false } },
        { label: 'часы', value: 'duration', config: { canSort: false } },
        { label: 'ФОТ', value: 'amount', config: { canSort: false } },
        { label: 'Налоги', value: 'Налоги', config: { canSort: false } },
        { label: 'Исполнитель', value: 'laborer', config: { canSort: false } },
      ]
    }
  },
  computed: {
    getTablePaginator () {
      const paginator = this.$store.state.shift.paginator
      if (!paginator) {
        return 0
      }

      if (this.statusGroup === 'active') {
        return parseInt(`${this.$store.state.shift.paginator.ActiveNumber}`, 10)
      }

      if (this.statusGroup === 'inactive') {
        return parseInt(`${this.$store.state.shift.paginator.inActiveNumber}`, 10)
      }
      return 0
    },
    mappedShiftList () {
      if (!this.shiftList) {
        return []
      }
      return this.shiftList.map((item) => {
        return {
          ...item,
          startDate: AppDateUtil.offerDateTime(item.startAt, 'DD.MM.YY'),
          endDate: AppDateUtil.offerDateTime(item.endAt, 'DD.MM.YY'),
        }
      })
    }
  },
  watch: {
    options: {
      handler () {
        const filter = {
          ...this.options
        }
        this.$emit('onVacancyPaginationChange', filter)
      },
      deep: true,
    }
  },
  created () {
  },
  methods: {
    goToView (shift) {
      this.$router.push({
        name: routeList.OFFICE_SHIFT_VIEW__NAME,
        params: { id: this.$route.params.id, shiftId: shift.id },
      })
    },
  }
}
</script>

<style scoped>

</style>
