<template>
  <AppMainContainer
    center-class="offer"
    :show-modal="!!currentModal"
    @isScroll="(val) => {isScroll = val}"
    @closeModal="closeModal"
  >
    <template #subheader>
      <PageHeader :isAdmin="isOrganizationRoot && !officeExists" />
      <OfficeSelect />

      <ShiftListFilterBarHeader
        v-if="officeExists"
        :filters="filters"
        :current-layout="currentLayout"
        @changeStatus="(value) => filters.statusGroup = value"
        @changeLayout="(value) => {currentLayout = value}"
      />
    </template>
    <template #center>
      <app-grid-list
        v-if="officeExists && currentLayout === 'grid'"
        :list="shiftList"
        :isShowLoad="pageLoading"
        item-class="shift__grid__item"
        :event="fetchShiftList"
      >
        <ShiftGridItem
          v-for="(shift, index) in shiftList"
          :key="index"
          :shift-prop="shift"
          :currentDate="currentDate"
          @click="clickShift"
          @updateShift="fetchShiftList"
          @finishShift="finishShift"
        />
      </app-grid-list>

      <shift-table
        v-else-if="currentLayout === 'table'"
        :shiftList="shiftList"
        :isShowLoad="pageLoading"
        :server-items-length="getTablePaginator"
        @onVacancyPaginationChange="onVacancyPaginationChange"
      />
    </template>

    <template #modal>
      <AppModalSmall
        v-if="currentModal === modalName.rateShift"
        style="padding: 70px 106px"
      >
        <h1 class="modal__header">
          Смена завершена!
        </h1>
        <p class="modal__description">
          Смена успешно завершена, оцените работника:
        </p>
        <div style="margin-top: 20px; margin-left: 16px; text-align: center">
          <OfferItemChatUserCard
            v-if="form.finishRate.laborer"
            class="shift-grid__user-info"
            :laborer="form.finishRate.laborer"
          />
        </div>
        <div
          class="star"
          style="text-align: center"
        >
          <AppStarWrapper
            width="36"
            height="32"
            :value="form.starValue"
            edit
            :count="5"
            is-rating
            @hover="hover"
            @click="submitStarForm"
          />
        </div>
        <div class="modal__button">
          <AppButton
            class="button-text-simple"
            @click.native="currentModal = ''"
          >
            Нет, спасибо!
          </AppButton>
        </div>
      </AppModalSmall>

      <AppModalSmall
        v-if="currentModal === modalName.successRateShift"
        style="padding: 70px 106px"
      >
        <h1 class="modal__header">
          Спасибо за оценку!
        </h1>
        <p class="modal__description">
          Вы помогаете нам быть лучше!
        </p>
      </AppModalSmall>
    </template>
  </AppMainContainer>
</template>

<script>
import OfficeSelect from '@/components/common/OfficeSelect'
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import ShiftListFilterBarHeader from '@/components/shift/ShiftListFilterBarHeader'
import officeApi from '@/mixins/office/officeApi'
import AppGridList from '@/components/common/AppGridList'
import ShiftGridItem from '@/components/shift/ShiftGridItem'
import ShiftTable from '@/components/shift/ShiftTable'
import AppModalSmall from '@/components/common/modal/AppModalSmall'
import AppButton from '@/components/common/simple/AppButton'
import userUtil from '@/utils/user-util'
import OfferItemChatUserCard from '@/components/offer/OfferItemChatUserCard'
import AppStarWrapper from '@/components/common/AppStarWrapper'
import PageHeader from '@/components/subheaders/PageHeader.vue'
const modalName = Object.freeze({
  rateShift: 'rateShift',
  successRateShift: 'successRateShift',
})

export default {
  name: 'PageShiftList',
  components: {
    PageHeader,
    AppButton,
    AppStarWrapper,
    AppModalSmall, ShiftTable,
    ShiftGridItem, AppGridList,
    ShiftListFilterBarHeader, AppMainContainer,
    OfficeSelect,
    OfferItemChatUserCard,
  },
  mixins: [officeApi],
  data () {
    return {
      isScroll: false,
      pageLoading: false,
      filters: {
        statusGroup: this.$route.query.statusGroup || 'accepted'
      },
      currentLayout: 'grid',

      currentDate: new Date(),

      currentModal: '',
      modalName,

      form: {
        starValue: 5,
        finishRate: null,
      },
    }
  },
  computed: {
    getTablePaginator () {
      const paginator = this.$store.state.shift.paginator
      if (!paginator) {
        return 0
      }

      const { statusGroup } = this.filters
      const { ActiveNumber, PlanedNumber, createdNumber, inActiveNumber } = this.$store.state.shift.paginator
      if (statusGroup === 'active' || statusGroup === 'active') {
        return parseInt(`${ActiveNumber}`, 10) + parseInt(`${PlanedNumber}`, 10) +
          parseInt(`${createdNumber}`, 10)
      }

      if (statusGroup === 'inactive') {
        return parseInt(`${inActiveNumber}`, 10)
      }
      return 0
    },
    shiftList () {
      const list = this.$store.state.shift.items
      return Array.isArray(list) ? list : []
    },
    officeExists () {
      return this.$store.state.office.userOffice && this.$store.state.office.userOffice.id
    },
    getUser () {
      return this.$store.state.users.info
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.getUser) || userUtil.isAdmin(this.getUser)
    },
  },
  watch: {
    'filters': {
      deep: true,
      handler () {
        this.fetchShiftList()
      }
    },
    '$route.query' (val) {
      if (val.statusGroup) {
        this.filters.statusGroup = val.statusGroup
      }
    },
  },

  destroyed () {
    // this.eventsBus.$off(this.eventNames.ON_LABORER_REACHED_OFFICE_GEO_ZONE_NOTIFICATION)
    // this.eventsBus.$off(this.eventNames.ON_RELAY_END_CONFIRMED_NOTIFICATION)
  },
  created () {
    this.eventsBus.$on(
      this.eventNames.ON_OFFER_WORKFLOW_SYSTEM_CANCEL_NOTIFICATION, (data) => {
        if (this.shiftList.length) {
          const filtered = this.shiftList.filter((item) => item.offer.id !== data.resourceId)
          const paginator = { ...this.$store.state.shift.paginator }
          switch (this.filters.statusGroup) {
          case 'accepted':
            paginator.PlanedNumber = filtered.length
            break
          case 'active':
            paginator.ActiveNumber = filtered.length
            break
          case 'inactive':
            paginator.inActiveNumber = filtered.length
            break
          }
          this.$store.commit('shift/replaceItems', filtered)
          this.$store.commit('shift/setPaginator', paginator)
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_OFFER_INVITATION_REJECTED_NOTIFICATION, () => {
        this.fetchShiftList()
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_LABOR_ACCEPTED_OFFER_INVITATION_NOTIFICATION, () => {
        this.fetchShiftList()
      }
    )
    this.eventsBus.$on(
      this.eventNames.ALIAS_ON_LABORER_TELEMETRY_START_NOTIFICATION, () => {
        this.fetchShiftList()
      }
    )
    this.eventsBus.$on(
      this.eventNames.ALIAS_ON_RELAY_REJECTED_NOTIFICATION, () => {
        this.fetchShiftList()
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_LABORER_REACHED_OFFICE_GEO_ZONE_NOTIFICATION, () => {
        this.fetchShiftList()
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_RELAY_START_NOTIFICATION, (data) => {
        this.fetchShiftList()
      }
    )
    this.eventsBus.$on(
      this.eventNames.ALIAS_ON_RELAY_REJECT_REQUEST_CANCELED_NOTIFICATION, () => {
        this.fetchShiftList()
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_RELAY_END_CONFIRMED_NOTIFICATION, (data) => {
        this.fetchShiftList()
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_RELAY_EXTEND_REQUEST_CONFIRMED_NOTIFICATION, (data) => {
        this.fetchShiftList()
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_RELAY_EXTEND_REQUEST_CANCELLED_NOTIFICATION, (data) => {
        this.fetchShiftList()
      }
    )
    this.eventsBus.$on(
      this.eventNames.ALIAS_ON_LABORER_ABSENCE_REJECTED_NOTIFICATION, (data) => {
        this.fetchShiftList()
      }
    )
    setInterval(() => {
      this.currentDate = new Date()
    }, 60 * 1000)
  },
  beforeDestroy () {
    this.$store.commit('shift/clearShiftItems')
  },
  methods: {
    onRouteReload () {
      this.fetchShiftList()
    },
    clickShift (item) {

    },

    finishShift (item) {
      if (item.status === 'active') {
        this.form.finishRate = item
        this.form.starValue = 5
        this.currentModal = this.modalName.rateShift
      }
    },
    onVacancyPaginationChange (options) {
      console.log(options, 'options')
      this.fetchShiftList(options.page, options.itemsPerPage)
    },
    fetchShiftList (page = 1, size = 50, reset = true) {
      this.$store.commit('shift/replaceItems', [])
      this.pageLoading = true
      return this.$store.dispatch('shift/fetchShiftList', { ...this.filters, page, size })
        .then((response) => {
          this.pageLoading = false
          return response
        })
    },

    closeModal () {
      this.currentModal = ''
    },

    hover (value) {
      this.form.starValue = value
    },

    submitStarForm (value) {
      this.form.starValue = value
      this.$store.dispatch('shift/fetchShiftEndReview', { rate: this.form.starValue, id: this.form.finishRate.id })
        .finally(() => {
          this.currentModal = this.modalName.successRateShift
          this.form.finishRate = null
        })
    }
  }
}
</script>

<style type="text/scss" scoped>
.star {
  justify-content: center;
  display: flex;
  margin-top: 10px;
  /*border: 1px solid red;*/
}
</style>
