<template>
  <div class="offer__filter-top">
    <div class="filter-top__left-group">
      <span
        v-for="item in statusGroups"
        :key="item.value"
        class="filter-top__element__text"
        :class="{'filter-top__element__text_active': filters.statusGroup === item.value}"
        @click="changeStatus(item.value)"
      >{{ item.label }} ({{ item.count }})</span>
    </div>

    <component
      :is="layout.label"
      v-for="layout in layouts"
      :key="layout.value"
      class="filter-top__element__icon"
      :class="layout.class"
      :isActive="currentLayout === layout.value"
      @click="$emit('changeLayout', layout.value)"
    />
  </div>
</template>

<script>
import IconPlus from '@/components/common/icons/IconPlus'
import IconPanEdit from '@/components/common/icons/IconPanEdit'
import IconGrid from '@/components/common/icons/IconGrid'
import IconTable from '@/components/common/icons/IconTable'
import { modalName } from '@/views/PageOfferList'

export default {
  name: 'ShiftListFilterBarHeader',
  components: { IconTable, IconGrid, IconPanEdit, IconPlus },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    currentLayout: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      modalName,
      statusGroups: [
        { label: 'Текущие', value: 'accepted', count: '0' },
        // { label: 'Активные', value: 'active', count: '0' },
        { label: 'Завершённые', value: 'inactive', count: '0' },
      ],
      layouts: [
        { label: IconGrid, value: 'grid', class: 'office__icon__grid' },
        { label: IconTable, value: 'table', class: 'office__icon__table' }
      ]
    }
  },
  computed: {
    getPaginator () {
      return this.$store.state.shift.paginator
    }
  },
  watch: {
    getPaginator (val) {
      this.statusGroups = [
        { label: 'Текущие', value: 'accepted', count: val.PlanedNumber },
        // { label: 'Активные', value: 'active', count: val.ActiveNumber },
        { label: 'Завершённые', value: 'inactive', count: val.inActiveNumber },
      ]
    }
  },
  created () {
  },
  methods: {
    changeStatus (type) {
      this.$emit('changeStatus', type)
    },
    changeOffer (type) {
      this.$emit('changeOffer', type)
    },
    createOffer () {
      this.$emit('createOffer')
    },
  }
}
</script>

<style lang="sass" scoped>
  .offer__filter-top
    display: flex
    align-items: center
    height: 45px

  .filter-top

    &__left-group, &__right-group
      margin-right: auto


</style>
