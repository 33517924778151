<script>
export default {
  name: 'ListMixin',
  data: () => ({
    itemsPerPageText: 'Кол -во строк на странице',
    startPage: 1,
    PageSize: 250,
    footerProps: {
      showFirstLastPage: true,
      itemsPerPageOptions: [50, 100, 250],
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus'
    }
  }),
  methods: {

  }
}
</script>
