<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.9317 9.5996C20.8642 9.45585 20.72 9.36388 20.5605 9.36388H19.348C18.577 5.18027 14.9034 2 10.5002 2C5.53779 2 1.5 6.03779 1.5 11.0002C1.5 15.9626 5.53738 20 10.5002 20C13.7029 20 16.6897 18.2763 18.2949 15.5024C18.5211 15.1109 18.3877 14.6109 17.9958 14.3843C17.6048 14.1577 17.1043 14.2911 16.8786 14.6826C15.5645 16.9531 13.1204 18.3641 10.5002 18.3641C6.44005 18.3641 3.13633 15.0608 3.13633 11.001C3.13633 6.94046 6.44005 3.63715 10.5002 3.63715C13.9974 3.63715 16.9274 6.08999 17.6739 9.3643H16.4709C16.3114 9.3643 16.1677 9.45626 16.0998 9.60001C16.0322 9.74376 16.0542 9.91319 16.1561 10.0354L18.2017 12.4899C18.2796 12.5831 18.3943 12.6369 18.5161 12.6369C18.6375 12.6369 18.7527 12.5827 18.8301 12.4899L20.8758 10.0354C20.9777 9.91278 20.9992 9.74334 20.9317 9.5996Z" fill="#8BD118"/>
    <path d="M10.5023 6.57463C10.1749 6.57463 9.90965 6.83387 9.90965 7.15382V10.6683C9.6417 10.8519 9.46527 11.1545 9.46527 11.4982C9.46527 12.0572 9.93065 12.512 10.5026 12.512C10.6592 12.512 10.8068 12.4754 10.94 12.4141L13.0014 13.5768C13.095 13.6299 13.1965 13.6549 13.2973 13.6549C13.5019 13.6549 13.7011 13.551 13.811 13.3651C13.9745 13.0886 13.8776 12.734 13.5937 12.5742L11.5302 11.4096C11.5026 11.1035 11.3397 10.836 11.0955 10.6689V7.15382C11.0949 6.83417 10.8293 6.57463 10.5023 6.57463Z" fill="#8BD118"/>
    <path d="M20.9317 9.5996C20.8642 9.45585 20.72 9.36388 20.5605 9.36388H19.348C18.577 5.18027 14.9034 2 10.5002 2C5.53779 2 1.5 6.03779 1.5 11.0002C1.5 15.9626 5.53738 20 10.5002 20C13.7029 20 16.6897 18.2763 18.2949 15.5024C18.5211 15.1109 18.3877 14.6109 17.9958 14.3843C17.6048 14.1577 17.1043 14.2911 16.8786 14.6826C15.5645 16.9531 13.1204 18.3641 10.5002 18.3641C6.44005 18.3641 3.13633 15.0608 3.13633 11.001C3.13633 6.94046 6.44005 3.63715 10.5002 3.63715C13.9974 3.63715 16.9274 6.08999 17.6739 9.3643H16.4709C16.3114 9.3643 16.1677 9.45626 16.0998 9.60001C16.0322 9.74376 16.0542 9.91319 16.1561 10.0354L18.2017 12.4899C18.2796 12.5831 18.3943 12.6369 18.5161 12.6369C18.6375 12.6369 18.7527 12.5827 18.8301 12.4899L20.8758 10.0354C20.9777 9.91278 20.9992 9.74334 20.9317 9.5996Z" stroke="#8BD118" stroke-width="0.2"/>
    <path d="M10.5023 6.57463C10.1749 6.57463 9.90965 6.83387 9.90965 7.15382V10.6683C9.6417 10.8519 9.46527 11.1545 9.46527 11.4982C9.46527 12.0572 9.93065 12.512 10.5026 12.512C10.6592 12.512 10.8068 12.4754 10.94 12.4141L13.0014 13.5768C13.095 13.6299 13.1965 13.6549 13.2973 13.6549C13.5019 13.6549 13.7011 13.551 13.811 13.3651C13.9745 13.0886 13.8776 12.734 13.5937 12.5742L11.5302 11.4096C11.5026 11.1035 11.3397 10.836 11.0955 10.6689V7.15382C11.0949 6.83417 10.8293 6.57463 10.5023 6.57463Z" stroke="#8BD118" stroke-width="0.2"/>
  </svg>
</template>

<script>
    import icon_config from "@/mixins/icon_config";

    export default {
        name: "IconReload",
        mixins: [icon_config],
    }
</script>

<style lang="sass" scoped>

</style>
